<template>
    <v-app>
        <v-navigation-drawer app class="navbar-drawer-dark" dark v-model="drawer" v-if="isLoggedIn && !isLoadingUser">
            <v-img :src="globalSettings.siteLogo ? globalSettings.siteLogo : '/img/logo.png'" max-height="128" min-height="128" contain></v-img>
            <!-- Navigation Bar Inhalt -->
            <v-list density="compact" nav>
                <!-- Dashboard -->
                <v-list-item prepend-icon="mdi-view-dashboard" title="Dashboard" value="dashboard" to="/dashboard"></v-list-item>
                
                <!-- Admin -->
                <v-list-group value="Admin" prepend-icon="mdi-security" v-if="canAccessAnyRoute(['/admin/users', '/admin/roles', '/admin/employees', '/admin/trainings', '/admin/applicationquestions', '/admin/messages', '/admin/map', '/admin/weather', '/admin/settings'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-security" title="Admin"></v-list-item>
                    </template>
                    <v-list-item title="Benutzer" value="user" to="/admin/users" v-if="canAccessRoute('/admin/users')"></v-list-item>
                    <v-list-item title="Rollen" value="roles" to="/admin/roles" v-if="canAccessRoute('/admin/roles')"></v-list-item>
                    <v-list-item title="Mitarbeiter" value="employees" to="/admin/employees" v-if="canAccessRoute('/admin/employees')"></v-list-item>
                    <v-list-item title="Schulungen" value="admin_training" to="/admin/trainings" v-if="canAccessRoute('/admin/trainings')"></v-list-item>
                    <v-list-item title="Bewerberfragen" value="applicationquestions" to="/admin/applicationquestions" v-if="canAccessRoute('/admin/applicationquestions')"></v-list-item>
                    <v-list-item title="Nachrichten" value="adminmessages" to="/admin/messages" v-if="canAccessRoute('/admin/messages')"></v-list-item>
                    <v-list-item title="Karte" value="adminmap" to="/admin/map" v-if="canAccessRoute('/admin/map')"></v-list-item>
                    <v-list-item title="Wetter" value="weather" to="/admin/weather" v-if="canAccessRoute('/admin/weather')"></v-list-item>
                    <v-list-item title="Settings" value="settings" to="/admin/settings" v-if="canAccessRoute('/admin/settings')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Leitstelle -->
                <v-list-group value="Leitstelle" prepend-icon="mdi-fire-truck" v-if="canAccessAnyRoute(['/dispatch', '/vehicle', '/crew'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-fire-truck" title="Leitstelle"></v-list-item>
                    </template>
                    <v-list-item title="Leitstelle" value="dispatch" to="/dispatch" v-if="canAccessRoute('/dispatch')"></v-list-item>
                    <v-list-item title="Fahrzeuge" value="vehicle" to="/vehicle" v-if="canAccessRoute('/vehicle')"></v-list-item>
                    <v-list-item title="Besatzung" value="crew" to="/crew" v-if="canAccessRoute('/crew')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Schwarzes Brett -->
                <v-list-group value="Schwarzes Brett" prepend-icon="mdi-developer-board" v-if="canAccessAnyRoute(['/blackboard/admin', '/blackboard/employee'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-developer-board" title="Schwarzes Brett"></v-list-item>
                    </template>
                    <v-list-item title="Schwarzes Brett" value="blackboardAdmin" to="/blackboard/admin" v-if="canAccessRoute('/blackboard/admin')"></v-list-item>
                    <v-list-item title="Schwarzes Brett Mitarbeiter" value="blackboardEmployee" to="/blackboard/employee" v-if="canAccessRoute('/blackboard/employee')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Mitarbeiter -->
                <v-list-group value="Mitarbeiter" prepend-icon="mdi-account-group" v-if="canAccessAnyRoute(['/employee', '/vacation'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-account-group" title="Mitarbeiter"></v-list-item>
                    </template>
                    <v-list-item :title="globalSettings.employeeNavigation || 'Mitarbeiter'" value="employee" to="/employee" v-if="canAccessRoute('/employee')"></v-list-item>
                    <v-list-item title="Urlaube" value="vacation" to="/vacation" v-if="canAccessRoute('/vacation')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Templates -->
                <v-list-item prepend-icon="mdi-file-edit-outline" title="Templates" value="template" to="/template" v-if="canAccessRoute('/template')"></v-list-item>
                
                <!-- Behörden -->
                <v-list-subheader>Extern</v-list-subheader>
                <v-divider></v-divider>
                <v-list-group value="Behörden" prepend-icon="mdi-home-group" v-if="canAccessAnyRoute(['/company', '/companytype'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-home-group" title="Behörden"></v-list-item>
                    </template>
                    <v-list-item title="Schwarzes Brett" value="blackboardGlobal" to="/blackboard/global" v-if="canAccessRoute('/blackboard/global')"></v-list-item>
                    <v-list-item title="Documents" value="documentsglobal" to="/document/global" v-if="canAccessRoute('/document/global')"></v-list-item>
                    <v-list-item title="Karte" value="mapglobal" to="/map/global" v-if="canAccessRoute('/map/global')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <!-- Unternehmen -->
                <v-list-group value="Unternehmen" prepend-icon="mdi-domain" v-if="canAccessAnyRoute(['/company', '/companytype'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-domain" title="Unternehmen"></v-list-item>
                    </template>
                    <v-list-item title="Unternehmen" value="company" to="/company" v-if="canAccessRoute('/company')"></v-list-item>
                    <v-list-item title="Typ" value="companytype" to="/companytype" v-if="canAccessRoute('/companytype')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Fahrzeuge / Wohnungen -->
                <v-list-item prepend-icon="mdi-account-file" title="Personen" value="person" to="/person" v-if="canAccessRoute('/person')"></v-list-item>
                <v-list-item prepend-icon="mdi-car-estate" title="Fahrzeuge" value="vehicleFile" to="/vehicleFile" v-if="canAccessRoute('/vehicleFile')"></v-list-item>
                <v-list-item prepend-icon="mdi-home" title="Wohnungen" value="apartmentFile" to="/apartmentFile" v-if="canAccessRoute('/apartmentFile')"></v-list-item>
                
                <!-- Organisation -->
                <v-list-subheader v-if="canAccessAnyRoute(['/todo', '/calendar', '/application', '/report'])">Organisation</v-list-subheader>
                <v-divider></v-divider>
                <v-list-group value="Berichte" prepend-icon="mdi-fire-truck" v-if="canAccessAnyRoute(['/report', '/reportcategory', '/reporttemplate', '/reportcode', '/reportadditional', '/reportstatus'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-fire-truck" title="Berichte"></v-list-item>
                    </template>
                    <v-list-item title="Berichte" value="report" to="/report" v-if="canAccessRoute('/report')"></v-list-item>
                    <v-list-item title="Kategorien" value="reportcategory" to="/reportcategory" v-if="canAccessRoute('/reportcategory')"></v-list-item>
                    <v-list-item title="Vorlagen" value="reporttemplate" to="/reporttemplate" v-if="canAccessRoute('/reporttemplate')"></v-list-item>
                    <v-list-item title="Code" value="reportcode" to="/reportcode" v-if="canAccessRoute('/reportcode')"></v-list-item>
                    <v-list-item title="Zusatz" value="reportadditional" to="/reportadditional" v-if="canAccessRoute('/reportadditional')"></v-list-item>
                    <v-list-item title="Status" value="reportstatus" to="/reportstatus" v-if="canAccessRoute('/reportstatus')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <v-list-item prepend-icon="mdi-format-list-checks" title="To-Do" value="todo" to="/todo" v-if="canAccessRoute('/todo')"></v-list-item>
                <v-list-item prepend-icon="mdi-calendar-clock" title="Calendar" value="calendar" to="/calendar" v-if="canAccessRoute('/calendar')"></v-list-item>
                <v-list-item prepend-icon="mdi-human-edit" title="Applications" value="application" to="/application" v-if="canAccessRoute('/application')"></v-list-item>
                
                <!-- Dokumente -->
                <v-list-subheader v-if="canAccessAnyRoute(['/department', '/document', '/training', '/administration'])">Allgemeines</v-list-subheader>
                <v-divider></v-divider>
                <v-list-group value="Dokumente" prepend-icon="mdi-file-document-outline" v-if="canAccessAnyRoute(['/department', '/document', '/training', '/administration'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-file-document-outline" title="Dokumente"></v-list-item>
                    </template>
                    <v-list-item title="Department" value="department" to="/department" v-if="canAccessRoute('/department')"></v-list-item>
                    <v-list-item title="Documents" value="document" to="/document" v-if="canAccessRoute('/document')"></v-list-item>
                    <v-list-item title="Trainings" value="trainings" to="/training" v-if="canAccessRoute('/training')"></v-list-item>
                    <v-list-item title="Administration" value="administration" to="/administration" v-if="canAccessRoute('/administration')"></v-list-item>
                    <v-list-item title="Test" value="tests" to="/test" v-if="canAccessRoute('/test')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Schulungen -->
                <v-list-group value="Schulungen" prepend-icon="mdi-book-open" v-if="canAccessAnyRoute(['/trainingassign', '/test'])">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-book-open" title="Schulungen"></v-list-item>
                    </template>
                    <v-list-item title="Übersicht" value="trainingassign" to="/trainingassign" v-if="canAccessRoute('/trainingassign')"></v-list-item>
                    <v-list-item title="Test Generieren" value="tests" to="/test" v-if="canAccessRoute('/test')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                
                <!-- Sonstiges -->
                <v-list-subheader v-if="canAccessAnyRoute(['/fireprotection', '/map', '/cheatsheet'])">Sonstiges</v-list-subheader>
                <v-divider></v-divider>
                <v-list-item prepend-icon="mdi-fire" title="Fire Protection" value="fireprotection" to="/fireprotection" v-if="canAccessRoute('/fireprotection')"></v-list-item>
                <v-list-item prepend-icon="mdi-map" title="Karte" value="map" to="/map" v-if="canAccessRoute('/map')"></v-list-item>
                <v-list-item prepend-icon="mdi-note-text" title="Cheatsheet" value="cheatsheet" to="/cheatsheet" v-if="canAccessRoute('/cheatsheet')"></v-list-item>
            </v-list>
        </v-navigation-drawer>

        <!-- App Bar -->
        <v-app-bar app dark v-if="isLoggedIn">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>{{ globalSettings.siteName || 'Kyuubi D. Dragon Administration Tool' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-if="isLoggedIn">
                <v-btn icon @click="goToMessages">
                    <v-badge :content="unreadMessagesCount" color="red">
                        <v-icon>mdi-email</v-icon>
                    </v-badge>
                </v-btn>
                <v-avatar>
                    <img src="https://via.placeholder.com/40" alt="Profile Image" />
                </v-avatar>
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                        <v-btn text v-bind="props">{{ userProfile.name }}</v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="goToProfile">
                            <v-list-item-title>Profil anzeigen</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editProfile">
                            <v-list-item-title>Profil bearbeiten</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout">
                            <v-list-item-title>Ausloggen</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>

        <!-- Main Content -->
        <v-main id="web-body">
            <router-view />
        </v-main>

        <!-- Snackbar für Benachrichtigungen -->
        <v-snackbar
            v-for="(notification, index) in notifications"
            :key="index"
            v-model="notification.visible"
            location="bottom right"
            timeout="5000"
            multi-line
            elevation="24"
            :stacked="true"
        >
            <div>
                <div>Neue Nachricht</div>
                <div>Von: {{ notification.senderName }}</div>
                <div>Titel: {{ notification.title }}</div>
            </div>
        </v-snackbar>
    </v-app>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeMount, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import api from "@/api";
import { isTokenExpired } from "@/store";
import { GlobalSettings } from "@/types/Settings";

export default defineComponent({
    name: 'App',
    setup() {
        const drawer = ref(true);
        const store = useStore();
        const router = useRouter();
        const globalSettings = ref<GlobalSettings>({});
        const socket = ref<WebSocket | null>(null);
        const notifications = ref<{title: string, senderName: string, visible: boolean}[]>([]);

        const authority = computed(() => store.state.user ? store.state.user.authority : '');
        const userProfile = computed(() => store.state.user || {});
        const unreadMessagesCount = computed(() => store.state.user?.unreadMessagesCount || 0);


        const setHtmlClass = (newClass: string) => {
            const htmlElement = document.documentElement;

            // Clear all existing classes
            htmlElement.className = '';

            // Add the new class only if it's not empty
            if (newClass) {
                htmlElement.classList.add(newClass);
            }
        };


        // Beobachte Änderungen der `authority` und setze die Klasse entsprechend
        watch(authority, (newAuthority) => {
            setHtmlClass(newAuthority);
        });

        const setupWebSocket = () => {
            const token = store.state.jwt;
            if (!token) return;

            socket.value = new WebSocket(`wss://system.kyuubiddragon.com/restapi/ws/?token=${token}`);

            socket.value.onopen = () => {
                console.log('WebSocket connected');
            };

            socket.value.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'update' && data.site === 'message') {
                    fetchUnreadMessagesCount();
                    showNotification(data.title, data.sender_name);
                }
                console.log('WebSocket message received:', data);
            };

            socket.value.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            socket.value.onclose = () => {
                console.log('WebSocket disconnected');
                // Automatically attempt to reconnect after 5 seconds
                setTimeout(setupWebSocket, 5000);
            };
        };

        const fetchGlobalSettings = async () => {
            try {
                const response = await api.get("settings/?action=getGlobalSettings");
                if (response.data && response.data.settings) {
                    return response.data.settings;
                }
            } catch (error) {
                console.error("Error fetching global settings:", error);
            }
            return {};
        };

        const fetchSettings = async () => {
            const settings = await fetchGlobalSettings();
            globalSettings.value = settings;
        };

        const isLoggedIn = computed(() => {
            const state = store.state;
            return !!state.jwt && !isTokenExpired(state);
        });

        const fetchUnreadMessagesCount = async () => {
            try {
                const response = await api.get("message/?action=getUnreadMessagesCount");
                store.dispatch('updateUnreadMessagesCount', response.data.unread_count);
            } catch (error) {
                console.error("Error fetching unread messages count:", error);
            }
        };

        const goToMessages = () => {
            router.push('/message');
        };

        const goToProfile = () => {
            router.push('/profile');
        };

        const editProfile = () => {
            router.push('/edit-profile');
        };

        const logout = async () => {
            try {
                await api.post("account/?action=logout");
                store.dispatch('logout');
                router.push({ name: 'login' });
            } catch (error) {
                console.error('Error logging out:', error);
            }
        };

        const showNotification = (title, senderName) => {
            notifications.value.push({ title, senderName, visible: true });
        };

        onMounted(async () => {
            await fetchSettings();
            await fetchUnreadMessagesCount();
            if (store.state.jwt && !isTokenExpired(store.state)) {
                setupWebSocket();
            }
            setHtmlClass(authority.value);
        });

        onBeforeMount(async () => {
            document.documentElement.className = '';
            await store.dispatch('reloadUserData');
        });

        // Prüfe Zugriff auf eine bestimmte Route
        const canAccessRoute = (routeName: string) => {
            const route = router.options.routes.find((r) => r.path === routeName);
            if (route && route.meta) {
                const { requiredPermission, requiredSite } = route.meta;
                const userPermissions = store.state.user.permissions;
                const userAuthority = store.state.user.authority;

                const hasPermission = !requiredPermission || 
                    (Array.isArray(userPermissions) && 
                        (userPermissions.includes(requiredPermission) || userPermissions.includes('ALL_PERMISSIONS')));
                const hasSiteAccess = !requiredSite || 
                    (Array.isArray(requiredSite) && requiredSite.includes(userAuthority));

                return hasPermission && hasSiteAccess;
            }
            return false;
        };

        // Prüfe Zugriff auf eine der gegebenen Routen
        const canAccessAnyRoute = (routes: string[]) => routes.some(route => canAccessRoute(route));

        return {
            drawer,
            isLoggedIn,
            userProfile,
            goToProfile,
            editProfile,
            logout,
            globalSettings,
            unreadMessagesCount,
            goToMessages,
            notifications,
            isLoadingUser: computed(() => store.state.isLoadingUser),
            canAccessRoute,
            canAccessAnyRoute
        };
    }
});
</script>

<style lang="scss">
#web-body {
    background-image: url('@/assets/background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
}

.disable-scroll {
    overflow: hidden;
    height: 100%;
}

.v-snackbar--multi-line .v-snackbar__wrapper {
    background-color: #212121;
    color: #ffffffab;
    border-radius: 8px;
}

.ck-editor__editable {
    resize: vertical;
    overflow: auto;
    min-height: 300px;
    padding: 30px;
}
.ck.ck-editor__main > .ck-editor__editable {
    padding-left: 30px;
}
.ck.ck-editor__top.ck-reset_all {
    z-index: var(--ck-z-modal);
    position: -webkit-sticky; /* Für Safari-Browser */
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--ck-color-toolbar-background);
}

.v-toolbar__content, .v-toolbar__extension, .v-navigation-drawer__content {
  background-color: var(--primary);
}
button, html [type="button"], [type="reset"], [type="submit"]{
    background-color: var(--primary);
    color:white;
}
.v-btn__content{
    color:white;
}
.text-info {
  color: var(--primary) !important;
}

.v-btn--icon.v-btn--density-default {
  background-color:#ffffff00 !important;
}
</style>
