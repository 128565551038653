<template>
	<v-card v-if="reportToEdit"> 
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Bericht Infos</v-tab>
			<v-tab :value="2">Text Feld</v-tab>
			<v-tab :value="3">Zusätze</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<v-col cols="4">
							<v-select
								label="Ersteller"
								v-model="report.creator"
								:items="employees"
								item-title="display_name"
								item-value="id"
								required
								:rules="[requiredRule]"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-select
								label="Kategorie"
								v-model="report.category_id"
								:items="categories"
								item-title="name"
								item-value="id"
								required
								:rules="[requiredRule]"
								:disabled="true"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-select
								label="Status"
								v-model="report.report_status_id"
								:items="statuses"
								item-title="name"
								item-value="id"
								required
								:rules="[requiredRule]"
							></v-select>
						</v-col>

						<template v-if="authority === 'fire'">
							<v-col cols="12">
								<v-text-field
									label="Brandursache"
									v-model="report.causeOfFire"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									label="Brandintensität"
									type="number"
									v-model.number="report.fireIntensity"
								></v-text-field>
							</v-col>
						</template>

						<template v-if="authority === 'police'">
							<v-col cols="12">
								<v-text-field
									label="Tatzeit"
									v-model="report.crimeTime"
									type="datetime-local"
								></v-text-field>
							</v-col>
						</template>

						<v-col cols="4">
							<v-text-field
								label="Datum"
								v-model="report.report_date"
								required
								:rules="[requiredRule]"
								type="datetime-local"
							></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-select
								label="Report Code"
								v-model="report.report_code_id"
								:items="reportCodes"
								item-title="code"
								item-value="id"
								required
								:rules="[requiredRule]"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-text-field
								label="Standort"
								v-model="report.location"
								required
								:rules="[requiredRule]"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								label="Titel"
								v-model="report.title"
								required
								:rules="[requiredRule]"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-autocomplete
								label="Verlinkte Personen"
								v-model="report.linked_persons"
								:items="persons"
								item-title="fullname"
								item-value="id"
								multiple
								chips
								closable-chips
							></v-autocomplete>
						</v-col>
						<v-col cols="12">
							<v-select
								v-model="report.missing_employees"
								:items="employees"
								item-title="display_name"
								item-value="id"
								label="Fehlende Berichtergänzungen"
								multiple
								chips
								closable-chips
							></v-select>
						</v-col>
						<v-col cols="12">
							<v-checkbox
								v-model="report.approved"
								label="Freigegeben"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="report.text"
					></ckeditor>
				</v-col>
				<v-col cols="12">
					<v-textarea
						label="Beschreibung"
						rows="3"
						v-model="report.description"
					></v-textarea>
				</v-col>
			</v-window-item>
			<v-window-item :value="3">
				<v-col cols="12" class="pa-4">
					<h3>Zusätzliche Leistungen</h3>
					<v-row
						v-for="(additional, index) in selectedAdditionals"
						:key="index"
						align="center"
					>
						<v-col cols="3">
							<v-select
								label="Zusatzleistung"
								v-model="additional.id"
								:items="additionals"
								item-title="name"
								item-value="id"
								@update:modelValue="
									onAdditionalSelected(index, $event)
								"
							></v-select>
						</v-col>
						<v-col cols="2">
							<v-text-field
								label="Preis"
								type="number"
								v-model.number="additional.price"
								@input="updateCalculations"
							></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-text-field
								label="HE"
								type="number"
								v-model.number="additional.units"
								@input="updateCalculations"
							></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-text-field
								label="Anzahl"
								type="number"
								v-model.number="additional.amount"
								@input="updateCalculations"
							></v-text-field>
						</v-col>
						<v-col cols="1">
							<v-btn
								icon
								@click="removeAdditional(index)"
								title="Zusatz entfernen"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" class="pa-4">
					<v-btn @click="addNewAdditional">Zusatz hinzufügen</v-btn>
				</v-col>

				<v-col cols="12" class="mt-4 pa-4">
					<v-divider></v-divider>
					<h4>Gesamtbetrag: {{ totalCost }} $</h4>
					<h4>Hafteinheiten: {{ totalUnits }}</h4>
				</v-col>
			</v-window-item>
		</v-window>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog"
				>Abbrechen</v-btn
			>
			<v-btn color="blue darken-1" text @click="updateReport"
				>Speichern</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import {
	Report,
	Employee,
	Category,
	ReportCode,
	ReportStatus,
} from "@/types/Report";
import { PersonFile } from "@/types/Person";
import EditorClass from "@/ckeditor/ckeditor.js";
import api from "@/api";

export default defineComponent({
	name: "EditReportDialog",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		reportToEdit: {
			type: Object as () => Report,
			required: true,
		},
		authority: {
			type: String,
			required: true,
		},
	},
	emits: ["update:modelValue", "reportUpdated", 'close'],
	setup(props, { emit }) {
		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		// Dynamische Felder basierend auf der Authority festlegen
		const getAuthoritySpecificFields = (authority: string) => {
			switch (authority) {
				case "fire":
					return { causeOfFire: "", fireIntensity: 0 };
				case "police":
					return { crimeTime: "" };
				default:
					return {};
			}
		};

		const report = ref<Report>({
			...props.reportToEdit,
			...getAuthoritySpecificFields(props.authority),
		});

		const statuses = ref<ReportStatus[]>([]);
		const employees = ref<Employee[]>([]);
		const categories = ref<Category[]>([]);
		const reportCodes = ref<ReportCode[]>([]);
		const additionals = ref<any[]>([]);
		const selectedAdditionals = ref<any[]>([]);
		const persons = ref<PersonFile[]>([]);

		// Daten abrufen
		const fetchStatuses = async () => {
			try {
				const response = await api.get("report/?action=getStatuses");
				statuses.value = response.data.map((item) => ({
					...item,
					id: Number(item.id),
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Status:", error);
			}
		};

		const fetchAdditionals = async () => {
			try {
				const response = await api.get("report/?action=getAdditionals");
				additionals.value = response.data.map((item) => ({
					...item,
					id: Number(item.id),
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Zusatzleistungen:", error);
			}
		};

		const fetchEmployees = async () => {
			try {
				const response = await api.get("report/?action=getEmployees");
				employees.value = response.data.map((emp: Employee) => ({
					...emp,
					display_name: `[${emp.servicenumber}] ${emp.name}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Mitarbeiter:", error);
			}
		};

		const fetchCategories = async () => {
			try {
				const response = await api.get("report/?action=getCategories");
				categories.value = response.data;
			} catch (error) {
				console.error("Fehler beim Laden der Kategorien:", error);
			}
		};

		const fetchReportCodes = async () => {
			try {
				const response = await api.get("report/?action=getCodes");
				reportCodes.value = response.data;
			} catch (error) {
				console.error("Fehler beim Laden der Report Codes:", error);
			}
		};

		const fetchPersons = async () => {
			try {
				const response = await api.get("personfile/?action=getPersons");
				persons.value = response.data.map((person: PersonFile) => ({
					...person,
					id: Number(person.id),
					fullname: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Personen:", error);
			}
		};

		// Update und Validation Methoden
		const requiredRule = (value: string) =>
			!!value || "Dieses Feld ist erforderlich.";

		const updateReport = async () => {
			try {
				await api.post("report/?action=editReport", {
					...report.value,
					additionals: selectedAdditionals.value,
					report_status_id: report.value.report_status_id,
				});
				emit("reportUpdated");
				closeDialog();
			} catch (error) {
				console.error("Fehler beim Speichern des Berichts:", error);
			}
		};

		const closeDialog = () => {
			emit("update:modelValue", false);
			emit("close"); // "close" Event emittieren
			dialog.value = false;
		};

		const updateCalculations = () => {
			selectedAdditionals.value = [...selectedAdditionals.value];
		};

		const addNewAdditional = () => {
			selectedAdditionals.value.push({
				id: null,
				price: 0.0,
				units: 1,
				amount: 1,
			});
		};

		const removeAdditional = (index) => {
			selectedAdditionals.value.splice(index, 1);
			updateCalculations();
		};

		const totalCost = computed(() => {
			return selectedAdditionals.value
				.reduce(
					(acc, additional) =>
						acc + additional.price * additional.amount,
					0
				)
				.toFixed(2);
		});

		const totalUnits = computed(() => {
			return selectedAdditionals.value.reduce(
				(acc, additional) => acc + additional.units * additional.amount,
				0
			);
		});

		const onAdditionalSelected = (index, selectedId) => {
			const additional = additionals.value.find(
				(item) => item.id === selectedId
			);

			if (additional) {
				selectedAdditionals.value[index].id = additional.id;
				selectedAdditionals.value[index].price = additional.price;
				selectedAdditionals.value[index].units = additional.units;
				selectedAdditionals.value[index].amount = 1;
			}
		};

		// Initialisiere Daten beim Öffnen des Dialogs
		watch(
			() => dialog.value,
			async (newVal) => {
				if (newVal) {
					report.value = {
						...props.reportToEdit,
						report_status_id: Number(
							props.reportToEdit.report_status_id
						),
					};

					if (
						props.reportToEdit.additionals &&
						props.reportToEdit.additionals.length > 0
					) {
						selectedAdditionals.value =
							props.reportToEdit.additionals.map(
								(additional) => ({
									id: Number(additional.id),
									price: additional.price,
									units: additional.units,
									amount: additional.amount,
								})
							);
					} else {
						selectedAdditionals.value = [];
					}

					await fetchPersons();
					await fetchEmployees();
					await fetchCategories();
					await fetchReportCodes();
					await fetchAdditionals();
					await fetchStatuses();
				}
			},
			{ immediate: true }
		);

		const currentTab = ref(1); // currentTab hier definieren

		return {
			dialog,
			report,
			statuses,
			employees,
			categories,
			reportCodes,
			additionals,
			selectedAdditionals,
			persons,
			totalCost,
			totalUnits,
			requiredRule,
			updateReport,
			closeDialog,
			addNewAdditional,
			removeAdditional,
			updateCalculations,
			onAdditionalSelected,
			EditorClass,
			currentTab
		};
	},
});
</script>
