<template>
	<!-- Personen bearbeiten Formular -->
	<v-card v-if="personToEdit">
		<v-card-title>
			Person bearbeiten
		</v-card-title>
		<!-- Tabs für verschiedene Formularinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Personeninfos</v-tab>
			<v-tab :value="2">Zusätzliche Informationen</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Personeninformationen -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<v-col cols="12">
							<v-row>
								<!-- Allgemeine Felder -->
								<v-col cols="2">
									<v-select
										label="Geschlecht"
										v-model="person.gender"
										:items="genderOptions"
										item-value="value"
										item-title="text"
										required
										:rules="[requiredRule]"
									></v-select>
								</v-col>
								<v-col cols="2">
									<v-text-field
										label="Titel"
										v-model="person.title"
									></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-text-field
										label="Vorname"
										v-model="person.firstname"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-text-field
										label="Nachname"
										v-model="person.lastname"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Geburtsdatum"
										v-model="person.birthday"
										type="date"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Geburtsort"
										v-model="person.birthplace"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Telefonnummer"
										v-model="person.phonenumber"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Personalausweisnummer"
										v-model="person.idcard"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Adresse"
										v-model="person.address"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Bankkonto"
										v-model="person.bankaccount"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="E-Mail"
										v-model="person.mail"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Lizenzen"
										v-model="person.licenses"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Einreise"
										v-model="person.entry"
										type="datetime-local"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										v-model="person.wanted"
										label="Gesucht"
									></v-checkbox>
								</v-col>

								<!-- Authority-spezifische Felder -->
								<template v-if="authority === 'fire'">

								</template>

								<template v-if="authority === 'police'">
									<v-col cols="12">
										<v-text-field
											label="Letzter bekannter Ort"
											v-model="person.lastKnownLocation"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Gangangehörigkeit"
											v-model="person.gangAffiliation"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'medic'">
									<v-col cols="12">
										<v-text-field
											label="Allergien"
											v-model="person.allergies"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Notfallkontakt"
											v-model="person.emergencyContact"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Versichert?"
											v-model="person.insured"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Blutgruppe"
											v-model="person.bloodType"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Erste Hilfe Kurs?"
											v-model="person.firstAid"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'justice'">
									<v-col cols="12">
										<v-text-field
											label="Letzter bekannter Ort"
											v-model="person.lastKnownLocation"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Gangangehörigkeit"
											v-model="person.gangAffiliation"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'statepark'">

								</template>

								<template v-if="authority === 'casa'">
									<v-col cols="12">
										<v-text-field
											label="Beruf"
											v-model="person.job"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Freiberufliche Tätigkeit"
											v-model="person.freelance"
										></v-text-field>
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="person.text"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Abbrechen
			</v-btn>
			<v-btn
				color="blue darken-1"
				text
				@click="updatePerson"
				:disabled="!isFormValid(person)"
			>
				Speichern
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, PropType } from "vue";
import { PersonFile } from "@/types/Person";
import EditorClass from "@/ckeditor/ckeditor.js";
import api from "@/api";
import { useStore } from "vuex";

export default defineComponent({
	name: "EditPersonFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		editPersonDialog: {
			type: Boolean,
			required: true,
		},
		personToEdit: {
			type: Object as PropType<PersonFile>,
			required: true,
		},
	},
	emits: ["update:modelValue", "personUpdated", 'close'],
	setup(props, { emit }) {
		const store = useStore();
		const authority = store.state.user.authority;

		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const person = ref<PersonFile>({ ...props.personToEdit });

		const currentTab = ref(1);

		watch(
			() => props.personToEdit,
			(newVal) => {
				person.value = { ...newVal };
			},
			{ immediate: true }
		);

		const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

		const updatePerson = async () => {
			try {
				await api.post("personfile/?action=editPerson", person.value);
				emit("personUpdated");
				closeDialog();
			} catch (error) {
				console.error("Fehler beim Aktualisieren der Person:", error);
			}
		};

		const isFormValid = (form: PersonFile) => form.firstname !== "" && form.lastname !== "" && form.birthplace !== "" && form.birthday !== "";

		const closeDialog = () => {
			dialog.value = false;
			emit("close");
		};

		const genderOptions = [
			{ text: "Männlich", value: "male" },
			{ text: "Weiblich", value: "female" },
			{ text: "Divers", value: "diverse" },
		];

		return {
			dialog,
			person,
			currentTab,
			requiredRule,
			updatePerson,
			isFormValid,
			closeDialog,
			EditorClass,
			genderOptions,
			authority,
		};
	},
});
</script>
